import { h, Component } from 'preact';
import styles from './styles.scss';
import { I18n, Config } from '../../shared/context';
import { UseDefaultUserIcon } from '../../shared/svg/icons/default-user';
import { Link } from '../../shared/link';
import { Button } from '../../shared/button';
import { Notifications } from '../notifications';
import { removeTeaserNotifications } from '../../shared/teaserNotifications';
import { UseCloseIcon } from '../../shared/svg/icons/close';
import { BellIcon } from '../../shared/svg/icons/bell';
import { withTheme } from '../../shared/withTheme';
import { breakpointSticky } from '../../sass/variables.scss';
import SignInPrompt from '../sign-in-prompt';
import NotificationTooltip from '../../shared/notif-tooltip';

let FocusTrap;
if (typeof window !== 'undefined') {
  FocusTrap = require('focus-trap-react');
}
const userMenuId = 'js-user-menu';
const maxNotifsDisplayed = 10;
const isStickyEnabled = () => {
  return window.innerWidth >= parseInt(breakpointSticky, 10);
};
const supportedEditions = ['au', 'ca', 'in', 'us', 'uk'];

class Toggle extends Component {
  render({ children, theme, ...props }) {
    return (
      <Button
        {...props}
        className={`${styles.menuToggle} ${theme.menuToggle}`}
        aria-controls={userMenuId}
        location={'avatar'}
      >
        <i>{children}</i>
      </Button>
    );
  }
}

const ThemedToggle = withTheme(Toggle);

const Avatar = ({
  image,
  username,
  unreadNotificationsCount,
  teaserNotifications,
  theme,
  ...props
}) => {
  let AvatarItself;

  if (teaserNotifications) {
    AvatarItself = (
      <div className={styles.bellContainer}>
        <BellIcon />
      </div>
    );
  } else if (image) {
    AvatarItself = (
      <Config.Consumer>
        {(config) => (
          <img
            className={`${styles.userAvatar} ${theme.userAvatar}`}
            src={config.image_service_url + image}
            alt={username}
            {...props}
          />
        )}
      </Config.Consumer>
    );
  } else {
    AvatarItself = (
      <UseDefaultUserIcon
        className={`${styles.userAvatar} ${theme.userAvatar}`}
        {...props}
      />
    );
  }

  return (
    <div className={styles.userAvatarContainer}>
      {AvatarItself}
      {unreadNotificationsCount > 0 && (
        <div
          key={unreadNotificationsCount}
          className={styles.userAvatarNotificationIcon}
        >
          <div className={styles.userAvatarNotificationCount}>
            {unreadNotificationsCount}
          </div>
        </div>
      )}
    </div>
  );
};

const ThemedAvatar = withTheme(Avatar);

const UserOptions = ({
  notifMenuEnabled,
  userOptionsEnabled,
  signInPromptEnabled,
  isEditionSupported,
  image,
  displayName,
  showNotifs,
  username,
  isCommunityUser,
  isContributorUser,
  onLogoutClicked,
  logoutXsrf,
  onUserMenuClicked,
  unreadNotifications,
  readNotifications,
  totalUnreadCount,
  isVisible,
  onMarkAsReadClicked,
  openSigninModal,
}) => {
  const cmsUrl = config => {
    if (isCommunityUser) {
      return config.community_url;
    } else if (isContributorUser) {
      return config.contributors_url;
    }

    return config.cms_url;
  };
  const dashbirdUrl = config => (isCommunityUser || isContributorUser) ? config.dashbird_community_url : config.dashbird_url;
  const CloseButton = (
    <ThemedToggle
      onClick={onUserMenuClicked}
      aria-expanded={String(isVisible)}
      aria-label={'close'}
    >
      <UseCloseIcon width={22} height={22} aria-hidden={true} />
    </ThemedToggle>
  );
  return (
    <Config.Consumer>
      {(config) => (
        <div>
          <div className={styles.userMenu}>
            {userOptionsEnabled && (
              <div className={styles.userOptionsProfile}>
                <Link
                  className={styles.userProfileLink}
                  href={`${config.bf_url}/${username}`}
                  location={'userMenu'}
                  label={'profile'}
                >
                  <ThemedAvatar image={image} username={username} />
                  <p className={styles.userProfileName}>
                    <span className={styles.userProfileNameText}>
                      {displayName}
                    </span>
                  </p>
                </Link>
                {CloseButton}
              </div>
            )}
            <I18n.Consumer>
              {(i18n) => (
                <div>
                  {userOptionsEnabled && (
                    <ul className={styles.userOptionsLinks}>
                      <li>
                        <Link
                          href={cmsUrl(config) + config.new_post_path}
                          location={'userMenu'}
                          label={'new post'}
                        >
                          {i18n.new_post}
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={cmsUrl(config)}
                          location={'userMenu'}
                          label={'drafts'}
                        >
                          {i18n.my_drafts}
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={dashbirdUrl(config)}
                          location={'userMenu'}
                          label={'dashboard'}
                        >
                          {i18n.dashboard}
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={config.settings_path}
                          location={'userMenu'}
                          label={'settings'}
                        >
                          Account Settings
                        </Link>
                      </li>
                      <li>
                        <form action={`${config.bf_url}/auth/signout`} method="post">
                          <input
                            type="hidden"
                            name="_xsrf"
                            value={logoutXsrf}
                          />
                          <Button
                            onClick={onLogoutClicked}
                            location={'userMenu'}
                            label={'logout'}
                          >
                            {i18n.logout}
                          </Button>
                        </form>
                      </li>
                    </ul>
                  )}
                  {signInPromptEnabled && (
                    <SignInPrompt
                      closeButton={CloseButton}
                      showRandomLoginCta={isEditionSupported}
                      openSigninModal={openSigninModal}
                    />
                  )}
                </div>
              )}
            </I18n.Consumer>
          </div>
          {notifMenuEnabled && showNotifs && (
            <Notifications
              unreadNotifications={unreadNotifications}
              readNotifications={readNotifications}
              totalUnreadCount={totalUnreadCount}
              onMarkAsReadClicked={onMarkAsReadClicked}
            />
          )}
        </div>
      )}
    </Config.Consumer>
  );
};

/*
  NotifUserMenuContainer duplicates the logic found in the MoreNav component
  customized to pass more props into UserOptions component
*/
class NotifUserMenuContainer extends Component {
  /**
   * Limits the height of the component to viewport height
   */
  componentDidUpdate() {
    if (!this.props.isVisible || !isStickyEnabled()) {
      return;
    }

    const { top } = this.container.getBoundingClientRect();
    this.container.style.maxHeight = `${
      document.documentElement.clientHeight - top
    }px`;
  }

  render({ isVisible, userInfo, onMarkAsReadClicked, ...props }) {
    return (
      <section
        className={`${styles.userMenuContainer}  ${
          this.props.isVisible ? styles.visible : ''
        }`}
        id={userMenuId}
        ref={(container) => (this.container = container)}
      >
        {this.props.isVisible && (
          <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true }}>
            <UserOptions
              {...userInfo}
              {...props}
              isVisible={isVisible}
              onMarkAsReadClicked={onMarkAsReadClicked}
            />
          </FocusTrap>
        )}
      </section>
    );
  }
}

class NotifUserMenu extends Component {
  constructor(props) {
    super(props);
    this.onMarkAsReadClicked = this.onMarkAsReadClicked.bind(this);
    this.onMarkTeaserAsReadClicked = this.onMarkTeaserAsReadClicked.bind(this);
    this.handleVisibiltyChange = this.handleVisibiltyChange.bind(this);
    this.longPollNotifications = this.longPollNotifications.bind(this);
    this.initNotifications = this.initNotifications.bind(this);
    this.state = {
      enabled: supportedEditions.includes(this.props.edition),
      unreadNotifications: [],
      readNotifications: [],
      totalUnreadCount: 0,
      authenticated: true,
      pageVisible: true,
      hidden: '',
      visibilityChange: '',
      documentTitle: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (!this.state.enabled || !this.props.userInfo) {
      return;
    }
    if (!prevProps.userInfo) {
      this.initNotifications();
    } else if (prevProps.isVisible === false && this.props.isVisible === true) {
      this.updateNotifications();
    }
    const unreadLength = this.state.totalUnreadCount;
    if (unreadLength > 0) {
      document.title = `(${unreadLength}) ${this.state.documentTitle}`;
      // otherwise if its 0, whether it matches or not, set the title
    } else {
      document.title = this.state.documentTitle;
    }
  }

  componentDidMount() {
    const { teaserNotifications } = this.props;

    if (
      teaserNotifications &&
      teaserNotifications.length > 0 &&
      !this.props.userInfo
    ) {
      this.setState({
        unreadNotifications: teaserNotifications,
        readNotifications: [],
        totalUnreadCount: teaserNotifications.length,
      });
      return;
    }

    this.setState({
      documentTitle: document.title,
    });

    if (this.state.enabled && this.props.userInfo) {
      this.initNotifications();
    }
  }

  initNotifications() {
    this.updateNotifications();
    this.setupPageVisibilityListener();
    this.timer = setInterval(this.longPollNotifications, 60 * 1000);
  }

  setupPageVisibilityListener() {
    let hidden, visibilityChange;
    if (typeof document.hidden !== 'undefined') {
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    this.setState({
      hidden,
      removeHandleVisbilityChange: () =>
        document.removeEventListener(
          visibilityChange,
          this.handleVisibiltyChange
        ),
    });

    document.addEventListener(
      visibilityChange,
      this.handleVisibiltyChange,
      false
    );
  }

  fetchNotifications(userID) {
    return fetch(
      `${this.props.bfURL}/notification-api/v1/notifications/summary?user_id=${userID}`,
      { credentials: 'include' }
    ).then((response) => {
      // on an error code, stop polling and hide the menu.
      //
      // We don't modify any cookies, they probably do have to re-auth though
      // to reach a login state again. If we reach this state then it means
      // they have an invalid `bfauth_session` token, but their
      // `bf2-b_info` cookie persists which the front end uses.
      //
      // This could be made more destructive in that we could actually
      // trigger some cookie deletion so the user is made more aware that
      // they are logged out. That would also benefit our metrics
      if (response.status >= 400 && response.status < 500) {
        this.setState({ authenticated: false });
        return null;
      }

      this.setState({ authenticated: true });

      // 304 means nothing has changed, the server will determine this from
      // the ETag we've passed in
      if (response.status === 304) {
        return null;
      }

      return response.json();
    });
  }

  handleVisibiltyChange() {
    if (document[this.state.hidden]) {
      this.setState({ pageVisible: false });
    } else {
      this.setState({ pageVisible: true });
    }
  }

  longPollNotifications() {
    if (this.state.authenticated && this.state.pageVisible) {
      this.updateNotifications();
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.state.removeHandleVisbilityChange();
  }

  updateNotifications() {
    if (!this.props.userInfo) {
      return;
    }

    this.fetchNotifications(this.props.userInfo.id)
      .then((response) => {
        const hasNotifications =
          response && (response.results_unread || response.results_read);
        if (!hasNotifications) {
          return;
        }
        // We want to show a combined maximum of `maxNotifsDisplayed` notifications
        const unreadNotifications = response.results_unread;
        const numberOfReadToShow = Math.max(
          maxNotifsDisplayed - unreadNotifications.length,
          0
        );
        const readNotifications = response.results_read.slice(
          0,
          numberOfReadToShow
        );
        this.setState({
          unreadNotifications,
          readNotifications,
          totalUnreadCount: response.unread_count || 0,
        });
      })
      .catch((err) => console.error(err));
  }

  // this function marks either single or all notifications as read
  onMarkAsReadClicked(
    notification,
    markPreviousRead = false
  ) {
    const { id } = notification;
    return fetch(
      `${this.props.bfURL}/notification-api/v1/notifications/${notification.id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          read: true,
          mark_previous_read: markPreviousRead,
        }),
        credentials: 'include',
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          if (markPreviousRead) {
            this.updateMarkAllAsReadState();
          } else {
            this.updateMarkAsReadState(id);
          }
        } else {
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onMarkTeaserAsReadClicked(_, markPreviousRead = false) {
    if (markPreviousRead) {
      removeTeaserNotifications();
      this.updateMarkAllAsReadState();
    }
  }

  // update state to reflect all notifications as read
  updateMarkAllAsReadState() {
    this.setState({
      unreadNotifications: [],
      readNotifications: [
        ...this.state.unreadNotifications,
        ...this.state.readNotifications,
      ],
      totalUnreadCount: 0,
    });
  }

  // update state to reflect one notification as read
  updateMarkAsReadState(id) {
    const updatedUnreadNotifications = this.state.unreadNotifications.filter(
      (notif) => notif.id !== id
    );
    const notification = this.state.unreadNotifications.find(
      (notif) => notif.id === id
    );
    this.setState({
      unreadNotifications: updatedUnreadNotifications,
      readNotifications: [notification, ...this.state.readNotifications],
      totalUnreadCount: Math.max(this.state.totalUnreadCount - 1, 0),
    });
  }

  render({
    notifMenuEnabled,
    userOptionsEnabled,
    signInPromptEnabled,
    teaserNotifications,
    isVisible,
    userInfo,
    onLogoutClicked,
    onUserMenuClicked,
    setShowNotifToolotip,
    onNotifTooltipClicked,
    logoutXsrf,
    edition,
    tooltipDismissed,
  }) {
    const isEditionSupported = supportedEditions.includes(edition);
    const isLoggedInUser = !!userInfo;
    const isNewOrCommunityUser =
      userInfo === null || (userInfo && userInfo.isCommunityUser);
    return (
      <div>
        <ThemedToggle
          onClick={onUserMenuClicked}
          aria-expanded={String(isVisible)}
          aria-label={'avatar'}
        >
          <NotificationTooltip
            onNotifTooltipClicked={onNotifTooltipClicked}
            setShowNotifToolotip={setShowNotifToolotip}
            tooltipDismissed={tooltipDismissed}
            teaserNotifications={teaserNotifications}
            isNewOrCommunityUser={isNewOrCommunityUser}
            isLoggedInUser={isLoggedInUser}
          >
            <ThemedAvatar
              image={userInfo && userInfo.image}
              username={userInfo && userInfo.username}
              onTouchStart={this.onTouchStart}
              teaserNotifications={teaserNotifications}
              unreadNotificationsCount={this.state.totalUnreadCount}
            />
          </NotificationTooltip>
        </ThemedToggle>
        <NotifUserMenuContainer
          bfURL={this.props.bfURL}
          edition={edition}
          isVisible={isVisible}
          userInfo={userInfo}
          onMarkAsReadClicked={
            userInfo ? this.onMarkAsReadClicked : this.onMarkTeaserAsReadClicked
          }
          onLogoutClicked={onLogoutClicked}
          logoutXsrf={logoutXsrf}
          onUserMenuClicked={onUserMenuClicked}
          unreadNotifications={this.state.unreadNotifications}
          readNotifications={this.state.readNotifications}
          totalUnreadCount={this.state.totalUnreadCount}
          teaserNotifications={teaserNotifications}
          notifMenuEnabled={notifMenuEnabled}
          userOptionsEnabled={userOptionsEnabled}
          signInPromptEnabled={signInPromptEnabled}
          showNotifs={this.state.enabled}
          isEditionSupported={isEditionSupported}
        />
      </div>
    );
  }
}

export default withTheme(NotifUserMenu);
