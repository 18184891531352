import { h } from 'preact';
import styles from './styles.scss';

export const NotificationPostTitle = ({ title = '' }) => {
  if (title.length > 65) {
    title = `${title.substr(0, 65).trim()}...`;
  }
  return (
    <span
      className={styles.postTitle}
      dangerouslySetInnerHTML={{ __html: title }}
    />
  );
};

export function IPAPostPublishNotification({ title }) {
  return (
    <span>
      You published <NotificationPostTitle title={title} />.
    </span>
  );
}

export function IPAViewThresholdNotification({ title, threshold }) {
  return (
    <span>
      Your post, <NotificationPostTitle title={title} />, got {threshold} views!
    </span>
  );
}

export function IPACommentHeartThresholdNotification({ threshold }) {
  return <span>Your comment got {threshold} hearts!</span>;
}

export function IPAPostPromotedNotification({ title }) {
  return (
    <span>
      Your post, <NotificationPostTitle title={title} />, was promoted.
    </span>
  );
}

export function PostPromoted() {
  return <span>Congratulations, your post was promoted!</span>;
}

export function CommentReplyNotification({ reply_guy = {}, title }) {
  const reply_name = reply_guy.display_name || reply_guy.username || 'Somebody';
  return (
    <span>
      {reply_name} replied to your comment on{' '}
      <span className={styles.postTitle}>
        <NotificationPostTitle title={title} />
      </span>
      .
    </span>
  );
}

export function CommentMentionNotification({ mentioned_by = {}, title }) {
  const mentioned_by_name = mentioned_by.display_name || mentioned_by.username || 'Somebody';
  return (
    <span>
      {mentioned_by_name} mentioned you on{' '}
      <span className={styles.postTitle}>
        <NotificationPostTitle title={title} />
      </span>
      .
    </span>
  );
}

export function TrophyAddedNotification({ trophy_name, trophy_img_url }) {
  return (
    <span className={styles.trophyAddedContainer}>
      <img
        alt={trophy_name}
        width="50"
        height="50"
        src={`${trophy_img_url}?resize=50:50`}
      />
      <span className={styles.postTitle}>{trophy_name}</span>
    </span>
  );
}

export function TeaserTrophyAddedNotification({ trophy_name, trophy_img_url }) {
  return (
    <span className={styles.trophyAddedContainer}>
      <img
        alt={trophy_name}
        width="50"
        height="50"
        src={`${trophy_img_url}?resize=50:50`}
      />
      <span className={styles.postTitle}>{trophy_name}</span>
      <button className={styles.buttonCTA} type="button">
        Sign in to claim this trophy{' '}
        <span className={styles.buttonCTAChevron}>&rsaquo;</span>
      </button>
    </span>
  );
}

export const NOTIFICATION_COMPONENT_TYPES = {
  comments_new_reply: CommentReplyNotification,
  comments_new_mention: CommentMentionNotification,
  post_promote: PostPromoted,
  ipa_post_promote: IPAPostPromotedNotification,
  ipa_post_publish: IPAPostPublishNotification,
  ipa_view_threshold: IPAViewThresholdNotification,
  ipa_comment_heart_threshold: IPACommentHeartThresholdNotification,
  ipa_trophy_added: TrophyAddedNotification,
  teaser_trophy_added: TeaserTrophyAddedNotification,
};
